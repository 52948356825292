import axios from 'axios'

const CryptoJS = require('crypto-js');
import {
	Message
} from 'element-ui'
import {
	showLoading,
	hideLoading
} from './loading'
// import VueCookies from 'vue-cookies'
axios.defaults.timeout = 50000
axios.defaults.baseURL = URL_AJAX
// axios.defaults.baseURL = 'http://shopapitest.hexagonmi.com.cn'
axios.defaults.headers.post['Content-Type'] =
	'application/x-www-form-urlencoded'

if (process.env.NODE_ENV === "development") {
	axios.defaults.baseURL = '/api'
} else {
	axios.defaults.baseURL = URL_AJAX
}
axios.interceptors.request.use(
	config => {
		if (localStorage.getItem('userToken') && !config.headers.Authorization) {
			// 6MVgiurOeFTzs0MyLV5vRn2f4tAN3G6F6WPG1ZTpO74=
			config.headers['Authorization'] = localStorage.getItem('userToken')
		}
		var str = window.location.href;
		var reg = RegExp(/payment/);

		var str1 = window.location.href;
		var reg1 = RegExp(/pluspay/);

		if (str.match(reg) || str1.match(reg1)) {
			// 包含
		} else {
			showLoading()
		}
		return config
	},
	error => {
		return Promise.error(error)
	}
)

axios.interceptors.response.use(
	response => {
		hideLoading()

		return response
	},

	error => {
		hideLoading()

		let url = encodeURI(window.location.href)
		if (error.response.status) {
			switch (error.response.status) {
				case 500:
					// console.log(500)
					break
				case 401:
					// Message.error('请先登录')
					// LoginByCIAM()
					// window.open(`${URL_LOGIN}?returnurl=${url}`, '_blank')
					// 	.location
					break

				default:
			}
			return Promise.reject(error.response)
		}
	}
)

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params, headers = {}) {
	return new Promise((resolve, reject) => {
		axios
			.get(url, {
				params: params,
				headers: headers
			})
			.then(res => {
				resolve(res.data)
			})
			.catch(err => {
				reject(err.data)
			})
	})
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params, headers = {}) {
	return new Promise((resolve, reject) => {
		axios
			.post(url, params, headers)
			.then(res => {
				resolve(res.data)
			})
			.catch(err => {
				reject(err.data)
			})
	})
}


function LoginByCIAM() {
	function generateCodeVerifier() {
		//生成随机code_verifier，
		return generateRandomString(32);
	}

	function generateState() {
		//生成随机state
		return generateRandomString(32);
	}

	function generateRandomString(length) {
		var text = '';
		var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		for (var i = 0; i < length; i++) {
			text += possible.charAt(Math.floor(Math.random() * possible.length));
		}
		return text;
	}

	function generateCodeChallenge(code_verifier) {
		//根据code_verifier生成code_challenge
		return base64URL(CryptoJS.SHA256(code_verifier));
	}

	function base64URL(string) {
		return string
			.toString(CryptoJS.enc.Base64)
			.replace(/=/g, '')
			.replace(/\+/g, '-')
			.replace(/\//g, '_');
	}
	let code_verifier = generateState();
	localStorage.setItem('code_verifier', code_verifier);
	// Message.success('正在跳转登录');
	setTimeout(() => {
		let url =
			CIAM_HOST +
			'/oauth2/authorize?scope=openid&client_id=' + CLIENT_ID + '&redirect_uri=' +
			encodeURIComponent(URL_LOGIN) + '&response_type=code&state=' +
			generateState() + '&code_challenge_method=S256&code_challenge=' + generateCodeChallenge(
				code_verifier)
		window.location.href = url;
	}, 1000);
}
