<template>
	<div id="app"><router-view /></div>
</template>

<script>
	import {
		post,
		get
	} from '@/http';
	export default {
		data() {
			return {};
		},
		// beforeCreate() {
		// 	if (this.$cookies.get('hexagon') && !localStorage.getItem('userToken')) {
		// 		localStorage.setItem('userToken', this.$cookies.get('hexagon'))
		// 	}
		// },
		mounted() {
			this.initLogin();
		},
		methods: {
			initLogin() {
				if (localStorage.getItem('userToken')) {
					if (this.isJsonString(localStorage.getItem('tokenJson'))) {
						let tokenJson = JSON.parse(localStorage.getItem('tokenJson'));
						if (tokenJson.refresh_token) {
							this.refreshToken(tokenJson.refresh_token).then(res => {
								if (res.data && res.data.indexOf('invalid_request') == -1) {
									localStorage.setItem('tokenJson', res.data);
								}
							});
						}
					}
				} else {
					this.clearLocalStorage();
				}

				this.CIAMLoginBack()
					.then(userInfo => {
						if (userInfo) {
							if (userInfo.userID) {
								this.GetAuth(userInfo.userID).then(res => {
									if (res.data) {
										localStorage.setItem('userToken', res.data);
										// this.$cookies.set('hexagon', res.data, {
										// 	domain: '.hexagonmi.com.cn',
										// 	expires: "10D"
										// })
										this.$store.commit('setLogin', true);
									} else {
										this.$confirm('用户信息获取失败，是否继续登陆?', '提示', {
											confirmButtonText: '确定',
											cancelButtonText: '取消',
											type: 'warning'
										}).then(() => {
											this.LoginByCIAM();
										});
									}
								});
							} else {
								localStorage.setItem('userToken', userInfo);
								// this.$cookies.set('hexagon', userInfo, {
								// 	domain: '.hexagonmi.com.cn',
								// 	expires: "10D"
								// })
								this.$store.commit('setLogin', true);
							}
						}
					})
					.catch(err => {
						console.log(err);
						this.$confirm('登录失败，是否继续登陆?', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							this.LoginByCIAM();
						});
					});
			},
			delParam(paramKey) {
				var url = window.location.href; // 页面url
				var urlParam = window.location.href.split('?'); // 页面参数
				var beforeHash = urlParam[0];
				urlParam = urlParam[1];
				paramKey = paramKey.split(',');
				var arr = new Array();
				var nextUrl = '';
				if (urlParam && urlParam != '') {
					var urlParamArr = urlParam.split('&'); // 将参数按照&符分成数组
					for (var i = 0; i < urlParamArr.length; i++) {
						var paramArr = urlParamArr[i].split('='); // 将参数键，值拆开
						// 如果键雨要删除的不一致，则加入到参数中
						if (paramKey.indexOf(paramArr[0]) == -1) {
							arr.push(urlParamArr[i]);
						}
					}
				}

				if (arr.length > 0) {
					nextUrl = '?' + arr.join('&');
				}

				url = beforeHash + nextUrl;
				window.history.replaceState(null, null, url);
			},
			GetQueryValue(variable) {
				var query = window.location.search.substring(1);
				var vars = query.split('&');
				for (var i = 0; i < vars.length; i++) {
					var pair = vars[i].split('=');
					if (pair[0] == variable) {
						return pair[1];
					}
				}
				return false;
			},
			CIAMLoginBack() {
				return new Promise((resolve, reject) => {
					let loginCode = this.GetQueryValue('code');
					let code_verifier = localStorage.getItem('code_verifier');
					localStorage.setItem('code_verifier', ''); //不能删
					if (loginCode) {
						let t = setTimeout(() => {
							this.delParam('code,state');
						}, 100);
					}
					if (loginCode && code_verifier != '') {
						this.GetUserToken(loginCode, code_verifier)
							.then(res => {
								let data = res.data;
								if (this.isJsonString(data)) {
									data = JSON.parse(data);
									if (data.access_token && data.id_token) {
										//data.data.normal_login_token为发送Ajax获取到的token信息
										//参考资料：https://cloud.tencent.com/document/product/1441/64398  https://blog.csdn.net/skyblue_afan/article/details/121850178
										var strings = data.id_token.split('.'); //通过split()方法将token转为字符串数组
										//取strings[1]数组中的第二个字符进行解析
										var userinfo = JSON.parse(decodeURIComponent(escape(window.atob(strings[1].replace(/-/g, '+')
											.replace(/_/g, '/')))));
										//然后可以拿到解析后的数据，可以console.log()打印下，
										console.log(userinfo);
										//然后赋值自己需要的数据
										// var accountId = userinfo.accountId;

										localStorage.setItem('tokenJson', res.data);
										if (userinfo && userinfo.id) {
											if (userinfo && userinfo.userID) {
												resolve(userinfo);
											} else {
												const base = this;
												base.fullscreenLoading = true;
												getUserID(1);

												function getUserID(time) {
													base
														.GetUserID(userinfo.id)
														.then(res => {
															if (res.data) {
																base.fullscreenLoading = false;
																resolve(res.data);
															} else {
																if (time > 24) {
																	base.fullscreenLoading = false;
																	reject(100);
																} else {
																	let t = setTimeout(() => {
																		clearTimeout(t);
																		getUserID(time + 1);
																	}, 5000);
																}
															}
														})
														.catch(() => {
															base.fullscreenLoading = false;
															reject(1);
														});
												}
											}
										} else {
											reject(2);
										}

										// GetUserInfoByToken('Bearer ' + data.access_token)
										// 	.then(res => {
										// 		if (res && res.userID) {
										// 			// localStorage.setItem('UserInfo', res);
										// 			resolve(res);
										// 		} else {
										// 			reject(1);
										// 		}
										// 	})
										// 	.catch(err => {
										// 		reject(2);
										// 	});
									} else {
										reject(3);
									}
								} else {
									reject(4);
								}
							})
							.catch(() => {
								reject(5);
							});
					} else {
						resolve();
					}
				});
			},

			GetUserToken(code, verfier) {
				return new Promise((resolve, reject) => {
					post('Users/GetUserToken', {
							code,
							verfier
						})
						.then(res => {
							resolve(res);
						})
						.catch(err => {
							reject(err);
						});
				});
			},

			// GetAccessToken() {
			// 	return new Promise((resolve, reject) => {
			// 		get(CIAM_HOST+'/scim/api/token?client_id=OGI4MmY5NzJiNmZhNGViMjgzZjQyNzU1MDY3OGI1OWQ&client_secret=cBvsTyggkjEvzaLr2r0oa2nOkaEF1GSW')
			// 			.then(res => {
			// 				resolve(res);
			// 			})
			// 			.catch(err => {
			// 				reject(err);
			// 			});
			// 	});
			// },
			GetUserInfoByToken(token) {
				return new Promise((resolve, reject) => {
					get(
							CIAM_HOST + '/userinfo', {}, {
								Authorization: token
							}
						)
						.then(res => {
							resolve(res);
						})
						.catch(err => {
							reject(err);
						});
				});
			},

			GetAuth(id) {
				return new Promise((resolve, reject) => {
					post('Users/GetAuth', {
							UID: id
						})
						.then(res => {
							resolve(res);
						})
						.catch(err => {
							reject(err);
						});
				});
			},

			refreshToken(Refresh_token) {
				return new Promise((resolve, reject) => {
					post('Users/RefreshUserToken', {
							Refresh_token
						})
						.then(res => {
							resolve(res);
						})
						.catch(err => {
							reject(err);
						});
				});
			},

			GetUserID(CiamID) {
				return new Promise((resolve, reject) => {
					post('Users/GetUserID', {
							CiamID
						})
						.then(res => {
							resolve(res);
						})
						.catch(err => {
							reject(err);
						});
				});
			}
		}
	};
</script>
<style>
	#app {
		color: #2c3e50;
		width: calc(100vw - 17px);
		overflow: hidden;
	}
</style>