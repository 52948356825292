import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: '/',
		component: () => import('@/layout/BasicLayout'),
		redirect: '/home',
		children: [
			// 首页
			{
				name: 'Home',
				path: 'home',
				component: () => import('@/views/home/index')
			},
			// 产品参数
			{
				name: 'Commodity',
				path: 'commodity',
				component: () => import('@/views/commodity/index')
			},
			// 购物车
			{
				name: 'cart',
				path: 'cart',
				component: () => import('@/views/cart/index')
			},
			// 结算页面
			{
				name: 'settlement',
				path: 'settlement',
				component: () => import('@/views/settlement/index')
			},
			// 支付页面
			{
				name: 'payment',
				path: 'payment',
				component: () => import('@/views/payment/index')
			},
			// 商品列表
			{
				name: 'product-list',
				path: 'product-list',
				component: () => import('@/views/product-list/index')
			},
			// 退换货选择类型
			{
				name: 'typeGoods',
				path: 'typeGoods',
				component: () => import('@/views/typeGoods')
			},
			// plus支付
			{
				name: 'pluspay',
				path: 'pluspay',
				component: () => import('@/views/pluspay')
			}
		]
	},
	{
		name: 'tociam',
		path: '/tociam',
		component: () => import('@/views/tociam')
	},
	{
		path: '/me',
		name: '/me',
		component: () => import('@/layout/MeLayout'),
		children: [

			// 我的订单
			{
				name: 'order',
				path: 'order',
				component: () => import('@/views/order')
			},
			// 订单详情
			{
				name: 'orderDetails',
				path: 'orderDetails',
				meta: {
					contain: 'order'
				},
				component: () => import('@/views/orderDetails')
			},
			// 退款售后
			{
				name: 'sales',
				path: 'sales',
				component: () => import('@/views/sales')
			},
			// 评价晒单
			{
				name: 'evaluate',
				path: 'evaluate',
				component: () => import('@/views/evaluate')
			},
			// 优惠卷
			{
				name: 'coupon',
				path: 'coupon',
				component: () => import('@/views/coupon')
			},
			// 收货地址
			{
				name: 'addr',
				path: 'addr',
				component: () => import('@/views/addr')
			},
			// 积分
			{
				name: 'point',
				path: 'point',
				component: () => import('@/views/point')
			},
			// 去评价
			{
				name: 'toEvaluate',
				path: 'toEvaluate',
				component: () => import('@/views/toEvaluate')
			},
			// 退换货回填
			{
				name: 'fillIn',
				path: 'fillIn',
				component: () => import('@/views/fillIn')
			},
			// 填写物流信息
			{
				name: 'logistics',
				path: 'logistics',
				component: () => import('@/views/logistics')
			},
			// 退款货详情
			{
				name: 'goodsDetails',
				path: 'goodsDetails',
				meta: {
					contain: 'sales'
				},
				component: () => import('@/views/goodsDetails')
			},
			// 消息中心
			{
				name: 'news',
				path: 'news',
				component: () => import('@/views/news')
			},
			// 发票
			{
				name: 'invoice',
				path: 'invoice',
				component: () => import('@/views/invoice')
			},
			// 我的收藏
			{
				name: 'collection',
				path: 'collection',
				component: () => import('@/views/collection')
			},
			// 我的足迹
			{
				name: 'footprint',
				path: 'footprint',
				component: () => import('@/views/footprint')
			},
			// 意见反馈
			{
				name: 'feedback',
				path: 'feedback',
				component: () => import('@/views/feedback')
			},
			// plus
			{
				name: 'plus',
				path: 'plus',
				component: () => import('@/views/plus')
			},
			// 购买记录
			{
				name: 'record',
				path: 'record',
				component: () => import('@/views/record'),
				meta: {
					contain: 'plus'
				}
			}
		]
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		}
		return {
			x: 0,
			y: 0
		}
	},
	routes
})
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return routerPush.call(this, location).catch(error => error)
}



router.beforeEach((to, from, next) => {
	if (['Home', 'Commodity'].indexOf(to.name) > -1) {
		if (_isMobile()) { //跳转移动端
			switch (to.name) {
				case 'Home':
					window.location.href = "http://mshop.hexagonmi.com.cn"
					break
				case 'Commodity':
					window.location.href = "http://mshop.hexagonmi.com.cn/#/goods-details?goodsId=" + (to.query
						.pid ? to.query.pid : '')
					break
			}
		}
	}
	next()
})

function _isMobile() {
	let flag = navigator.userAgent.match(
		/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
	)
	return flag;
}


export default router