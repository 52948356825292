import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import App from './App.vue'
import router from './router'
import store from './store'
import './style/index.less'
import './icon/iconfont.css'
import VueCookies from 'vue-cookies'
import Print from 'vue-print-nb'
// import YSF from '@neysf/qiyu-web-sdk';
Vue.use(VueCookies)
// YSF.init('8961211118a56a30638a2056058307de', {}).then(ysf => {
// 	Vue.prototype.ysf = ysf
// 	// ysf('open');
// }).catch(error => {
// 	console.log('sdk加载失败---', error);
// });




Vue.use(Print) //注册
Vue.use(ElementUI)
if (process.env.NODE_ENV === "development") {
	Vue.prototype.baseURL = '/api'
} else {
	Vue.prototype.baseURL = URL_AJAX
}
Vue.prototype.isJsonString = function(str) {
	try {
		if (str && str.length > 0 && typeof JSON.parse(str) == "object") {
			return true
		}
	} catch (e) {
		return false
	}
	return false
}

Vue.prototype.getCartStorage = function() {
	let cartList = [];
	let storage = localStorage.getItem('cartList')
	if (this.isJsonString(storage)) {
		cartList = JSON.parse(storage);
	}
	return cartList
}

Vue.prototype.getCartUrl = function() {
	let cartList = this.getCartStorage();
	let cartListStr = [];
	cartList.forEach(v => {
		cartListStr.push(v.code + '|' + v.count);
	});
	return cartListStr.join(',')
}

Vue.prototype.setCartStorage = function(newCartList, cover = 0) {
	//cover:0添加 1覆盖原有数量 2直接替换原来的
	if (cover == 2) {
		localStorage.setItem('cartList', JSON.stringify(newCartList))
		return false
	}
	let cartList = this.getCartStorage()
	if (Array.isArray(newCartList) && newCartList.length) {
		if (Array.isArray(cartList) && cartList.length) {
			for (let i = 0; i < newCartList.length; i++) {
				let flag = false
				for (let j = 0; j < cartList.length; j++) {
					if (cartList[j].code == newCartList[i].code) {
						if (cover == 1) {
							cartList[j].count = newCartList[i].count
						} else {
							cartList[j].count += Number(newCartList[i].count)
						}
						flag = true
					}
				}
				if (!flag) {
					cartList.unshift(newCartList[i])
				}
			}
			localStorage.setItem('cartList', JSON.stringify(cartList))
		} else {
			localStorage.setItem('cartList', JSON.stringify(newCartList))
		}
	}
}


const CryptoJS = require('crypto-js');
Vue.mixin({
	data() {
		return {}
	},
	computed: {},
	onLoad() {

	},
	onShow() {},
	methods: {
		clearLocalStorage() {
			// localStorage.setItem('u', '');
			// localStorage.setItem('tokenJson', '');
			// localStorage.setItem('userToken', '');
			// this.isLogin = false;
			this.$store.commit('setLogin', false)
		},
		toCIAMLoginIn(txt = '正在跳转登陆') {
			this.clearLocalStorage()
			this.LoginByCIAM(txt);
		},
		LoginByCIAM(txt = '正在跳转登陆', timeOut = 1000) {
			function generateCodeVerifier() {
				//生成随机code_verifier，
				return generateRandomString(32);
			}

			function generateState() {
				//生成随机state
				return generateRandomString(32);
			}

			function generateRandomString(length) {
				var text = '';
				var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for (var i = 0; i < length; i++) {
					text += possible.charAt(Math.floor(Math.random() * possible.length));
				}
				return text;
			}

			function generateCodeChallenge(code_verifier) {
				//根据code_verifier生成code_challenge
				return base64URL(CryptoJS.SHA256(code_verifier));
			}

			function base64URL(string) {
				return string
					.toString(CryptoJS.enc.Base64)
					.replace(/=/g, '')
					.replace(/\+/g, '-')
					.replace(/\//g, '_');
			}
			let code_verifier = generateState();

			localStorage.setItem('code_verifier', code_verifier);
			// this.$message.success(txt);
			setTimeout(() => {
				let url =
					CIAM_HOST +
					'/oauth2/authorize?scope=openid&client_id=' + CLIENT_ID + '&redirect_uri=' +
					encodeURIComponent(URL_LOGIN) + '&response_type=code&state=' +
					generateState() + '&code_challenge_method=S256&code_challenge=' +
					generateCodeChallenge(
						code_verifier)
				window.location.href = url;
			}, timeOut);
		},
		handleToVideo(data) {
			window.open(data.Title, '_blank').location
		},
		handleToCompPage(data) {
			if (
				data.Detail.Type == 2 ||
				data.Detail.Type == 3 ||
				data.Detail.Type == 4
			) {
				// 商品列表
				window.open(
					`/product-list?type=${data.Detail.Type}&link=${data.Detail.LinkID}`,
					'_blank'
				).location
			} else if (data.Detail.Type == 1) {
				let u = localStorage.getItem('u')
				window.open(
					`/commodity?pid=${data.Detail.LinkID}&u=${u}`,
					'_blank'
				).location
			} else if (data.Detail.Type == 5) {
				window.open(data.Detail.Title, '_blank').location
			} else if (data.Detail.Type == 6) {
				if (!localStorage.getItem('userToken')) {
					this.toCIAMLoginIn()
					// localStorage.setItem('u', '')
					// this.$message.success('正在跳转登录')
					// setTimeout(() => {
					// 	let url = encodeURI(window.location.href)
					// 	window.location.href = `${URL_LOGIN}?returnurl=${url}`
					// }, 1000)
				} else {
					receiveCoupon({
						DisID: data.Detail.LinkID
					}).then(res => {
						if (res.code == 0) {
							this.$message.error(res.msg)
						} else {
							this.$message.success(res.msg)
						}
					})
				}
			} else if (data.Detail.Type == 7) {
				let title = ''
				if (data.Detail.Title) {
					title = data.Detail.Title
				}
				window.open(
					`/product-list?aid=${data.Detail.LinkID}&title=${title}`,
					'_blank'
				).location
			}
		}
	}
})



Vue.config.productionTip = false
new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')