import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		add: false,
		isLogin: localStorage.getItem('userToken') ? true : false
	},
	mutations: {
		add(state) {
			state.add = !state.add
		},
		setLogin(state, value) {
			state.isLogin = value
			if (!value) {
				localStorage.setItem('u', '');
				localStorage.setItem('tokenJson', '');
				localStorage.setItem('userToken', '');
				// Vue.$cookies.remove('hexagon')
			}
		}
	},
	actions: {},
	modules: {}
})